export const regexMatches = {
  number: {
    regex: "^[0-9]+$",
    error: "must be a number",
  },
  "MM/DD/YYYY": {
    regex: "^[0-1]{1}[0-9]{1}\\/[0-3]{1}[0-9]{1}\\/[0-9]{4}$",
    error: "must be formatted in MM/DD/YYYY",
  },
  email: {
    regex:
      "(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])",
    error: "must be email",
  },
  zipcode: {
    regex: "^([0-9]{5})|([0-9]{5}-[0-9]{4})$",
    error: "must be zipcode",
  },
  letters: { regex: "^[a-zA-Z]+$", error: "must be letters only" },
  "3 char currency": { regex: "^[A-Z]{3}$", error: "3 character currency" },
  "alpha chars || spaces": {
    regex: "^[\\w ]+$",
    error: "must be alphachar or spaces only",
  },
  "<= 40 chars": {
    regex: "/^.{0,40}$/",
    error: "must be less than 40 characters",
  },
};

export const regexOptions = Object.keys(regexMatches).map((key) => {
  return { label: key };
});
