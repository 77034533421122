const getClassName = (tab, activeTab) => {
  const className =
    "text-purple-500 hover:text-purple-700 px-3 py-2 font-medium text-sm rounded-md cursor-pointer";
  return tab === activeTab ? `${className} bg-purple-100` : className;
};

export const ConfigTabs = ({ activeTab, setActiveTab }) => {
  return (
    <div className="flex justify-center items-center">
      <div className="hidden sm:block ">
        <nav
          className="flex space-x-4 justify-center items-center"
          aria-label="Tabs"
        >
          <div
            onClick={() => setActiveTab("Fields")}
            className={getClassName("Fields", activeTab)}
          >
            Fields
          </div>
          <div
            onClick={() => setActiveTab("Global Configs")}
            className={getClassName("Global Configs", activeTab)}
          >
            Global Configs
          </div>
        </nav>
      </div>
    </div>
  );
};
