import { styled } from "../stiches.config";
import { CopyToClipboard } from "react-copy-to-clipboard";

import ReactJson from "react-json-view";

const StyledSidebar = styled("div", {
  padding: "32px",
  width: "400px",
  height: "100vh",
  maxHeight: "100vh",
  overflow: "scroll",
});

export const SchemaPreview = ({ onClosePanel, schema }) => {
  return (
    <StyledSidebar className="shadow-xl h-full">
      <div className="ml-3 h-7">
        <button
          onClick={onClosePanel}
          className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <svg
            className="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <ReactJson
        src={schema}
        name={false}
        theme="shapeshifter:inverted"
        displayDataTypes={false}
        enableClipboard={(copy) =>
          navigator.clipboard.writeText(JSON.stringify(copy.src))
        }
      />
    </StyledSidebar>
  );
};
