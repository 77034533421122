// @ts-nocheck
import { useEffect, useRef, useState } from "react";

import Penpal from "penpal";

export function Preview({ theme, stage }) {
  const iframeRef = useRef();
  const resolver = useRef();
  const child = useRef();
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    const appendTo = iframeRef.current;

    child.current = new Promise((resolve) => {
      resolver.current = resolve;
    });
    const connection = Penpal.connectToChild({
      url: `${
        process.env.NODE_ENV === "production"
          ? "https://portal-2.flatfile.io"
          : "http://localhost:2000"
      }/?demo=1`,
      appendTo,
    });

    connection.promise.then(resolver.current).catch(() => {
      setShowError(true);
    });

    // cleanup
    return () => {
      if (appendTo) {
        appendTo.querySelectorAll("iframe").forEach((i) => i.remove());
      }
    };
  }, []);

  useEffect(() => {
    child?.current.then((c) => {
      c.DEMO__setConfig({
        theme: {
          global: {
            backgroundColor: theme.global.backgroundColor,
            primaryTextColor: theme.global.primaryTextColor,
            secondaryTextColor: theme.global.secondaryTextColor,
            textColor: theme.global.textColor,
            successColor: theme.global.successColor,
            warningColor: theme.global.warningColor,
          },
          buttons: {
            primary: theme.buttons.primary,
            secondary: theme.buttons.secondary,
            columnMatchConfirm: theme.buttons.columnMatchConfirm,
          },
        },
        stage,
      });
    });
  }, [theme, stage]);

  if (showError) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <div className="px-4 py-2 rounded-md text-red-500 bg-red-100">
          Something went wrong
        </div>
      </div>
    );
  }

  return <div className="portal-preview" ref={iframeRef} />;
}
