import randomColor from "randomcolor";

// https://github.com/davidmerfield/randomColor

export const RandomColorsButton = ({ theme, setTheme }) => {
  const generateColors = () => {
    const newPalette = randomColor({ count: 10 });
    const updatedTheme = {
      ...theme,
      global: {
        ...theme.global,
        backgroundColor: newPalette[0],
        textColor: newPalette[1],
        secondaryTextColor: newPalette[2],
      },
      buttons: {
        ...theme.buttons,
        primary: {
          ...theme.buttons.primary,
          backgroundColor: newPalette[3],
          color: newPalette[4],
        },
        secondary: {
          ...theme.buttons.primary,
          backgroundColor: newPalette[5],
          color: newPalette[6],
        },
        columnMatchConfirm: {
          ...theme.buttons.columnMatchConfirm,
          backgroundColor: newPalette[6],
          color: newPalette[7],
        },
      },
    };
    setTheme(updatedTheme);
  };
  return (
    <button
      onClick={() => generateColors()}
      type="button"
      className="inline-flex items-center px-3 py-2 border border-transparent text-base font-medium rounded-md text-purple-700 bg-purple-100 hover:bg-purple-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 h-1/2"
    >
      Random
    </button>
  );
};
