import { Tabs } from "./Tabs";
import { ColorOptions } from "./ColorOptions";
import { ButtonStyler } from "./ButtonStyler";
import { defaultTheme } from "../utils/defaultStyles";

export const Sidebar = ({ activeTab, setActiveTab, theme, setTheme }) => {
  return (
    <div className="flex flex-col max-w-xs w-full max-h-full overflow-y-hidden">
      <div className="text-center py-4">
        <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>
      <div className="sidebar-content">
        {activeTab === "Colors" && (
          <ColorOptions setTheme={setTheme} theme={theme} />
        )}
        {activeTab === "Buttons" && (
          <ButtonStyler setTheme={setTheme} theme={theme} />
        )}
      </div>
      <div className="p-4 text-center">
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 border border-transparent text-center font-medium rounded-md text-purple-700 bg-purple-100 hover:bg-purple-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => setTheme(defaultTheme)}
        >
          Reset to default
        </button>
      </div>
    </div>
  );
};
