import ReactJson from "react-json-view";
// https://www.npmjs.com/package/react-json-view
export const JSONPreview = ({ theme }) => {
  const newObj = { ...theme };
  return (
    <div className="max-w-xs w-full max-h-full overflow-y-auto py-4 px-2 preview">
      <ReactJson
        src={newObj}
        name={false}
        theme="shapeshifter:inverted"
        displayDataTypes={false}
      />
    </div>
  );
};
