import { Button } from "./defaultStyles";

export const presetThemes = {
  clean: {
    global: {
      backgroundColor: "#fff",
      primaryTextColor: "#1a535c",
      secondaryTextColor: "#4ecdc4",
      textColor: "#1a535c",
      successColor: "#8ce18d",
      warningColor: "#ffe66d",
    },
    buttons: {
      primary: {
        ...Button,
        backgroundColor: "#4ecdc4",
        color: "#1a535c",
      },
      secondary: {
        ...Button,
        backgroundColor: "#05b2dc",
        color: "#FFF",
      },
      columnMatchConfirm: {
        ...Button,
        backgroundColor: "#4ecdc4",
        color: "#1a535c",
      },
    },
  },
  darkMode: {
    global: {
      backgroundColor: "#212327",
      textColor: "#c2c3c3",
      primaryTextColor: "#c2c3c3",
      secondaryTextColor: "#c2c3c3",
      successColor: "#c2c3c3",
      warningColor: "#c2c3c3",
    },
    buttons: {
      primary: {
        backgroundColor: "#00D1AF",
        color: "#002d3d",
        border: "1px solid transparent",
        padding: "10px 16px",
        fontSize: "16px",
        borderRadius: "1px",
        ":hover": {
          backgroundColor: "#00D1AF",
          border: "1px solid transparent",
          color: "#002d3d",
        },
      },
      secondary: {
        backgroundColor: "#d9ccff",
        border: "1px solid #ece6ff",
        color: "#002d3d",
        padding: "10px 16px",
        fontSize: "16px",
        borderRadius: "1px",
        ":hover": {
          backgroundColor: "#794cff",
          border: "1px solid #794cff",
          color: "#c2c3c3",
        },
      },
      columnMatchConfirm: {
        backgroundColor: "#d9ccff",
        border: "1px solid #ece6ff",
        color: "#002d3d",
        padding: "10px 16px",
        fontSize: "16px",
        borderRadius: "1px",
        ":hover": {
          backgroundColor: "#794cff",
          border: "1px solid #794cff",
          color: "#c2c3c3",
        },
      },
    },
  },
  randy: {
    global: {
      backgroundColor: "#fe5783",
      primaryTextColor: "#FFFFFF",
      secondaryTextColor: "#081b23",
      textColor: "#FFFFFF",
      successColor: "#58cd67",
      warningColor: "#ffd766",
    },
    buttons: {
      primary: {
        ...Button,
        backgroundColor: "#081b23",
        color: "#FFFFFF",
      },
      secondary: {
        ...Button,
        backgroundColor: "#FFFFFF",
        color: "#081b23",
      },
      columnMatchConfirm: {
        ...Button,
        backgroundColor: "#ee2757",
        color: "#FFFFFF",
      },
    },
  },
  chic: {
    global: {
      backgroundColor: "#2b2d42",
      primaryTextColor: "#edf2f4",
      secondaryTextColor: "#ef233c",
      textColor: "#edf2f4",
      successColor: "#74c69d",
      warningColor: "#ffd766",
    },
    buttons: {
      primary: {
        ...Button,
        backgroundColor: "#ffd766",
        color: "#2b2d42",
      },
      secondary: {
        ...Button,
        backgroundColor: "#FFFFFF",
        color: "#081b23",
      },
      columnMatchConfirm: {
        ...Button,
        backgroundColor: "#ee2757",
        color: "#FFFFFF",
      },
    },
  },
  pizza: {
    global: {
      backgroundColor: "#E12301",
      primaryTextColor: "#edf2f4",
      secondaryTextColor: "#DBA24A",
      textColor: "#edf2f4",
      successColor: "#0F720F",
      warningColor: "#E1D800",
    },
    buttons: {
      primary: {
        ...Button,
        backgroundColor: "#FFFA8A",
        color: "#2b2d42",
      },
      secondary: {
        ...Button,
        backgroundColor: "#FFFFFF",
        color: "#081b23",
      },
      columnMatchConfirm: {
        ...Button,
        backgroundColor: "#836436",
        color: "#FFFFFF",
      },
    },
  },
  bold: {
    global: {
      backgroundColor: "#f5f5f4",
      primaryTextColor: "#1D2830",
      secondaryTextColor: "#ef476f",
      textColor: "#1D2830",
      successColor: "#06d6a0",
      warningColor: "#ffc43d",
    },
    buttons: {
      primary: {
        ...Button,
        backgroundColor: "#1D2830",
        color: "#f5f5f4",
      },
      secondary: {
        ...Button,
        backgroundColor: "#FFFFFF",
        color: "#081b23",
      },
      columnMatchConfirm: {
        ...Button,
        backgroundColor: "#ee2757",
        color: "#FFFFFF",
      },
    },
  },
};
