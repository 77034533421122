export const Button = {
  backgroundColor: "#06c6ff",
  color: "#02394b",
  border: "none",
  borderRadius: "4px",
  boxShadow:
    "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
  ":hover": {
    backgroundColor: "#118CB0",
    color: "#02394b",
  },
};

export const ColumnMatchConfirmButton = {
  ...Button,
  backgroundColor: "#06c6ff",
};

export const SecondaryButton = {
  ...Button,
  backgroundColor: "#FFFFFF",
};

export const defaultTheme = {
  global: {
    backgroundColor: "#EAEFF5",
    textColor: "#1D2830",
    primaryTextColor: "#1D2830",
    secondaryTextColor: "#667D8D",
    successColor: "#60b700",
    warningColor: "#f6A623",
  },
  buttons: {
    primary: Button,
    secondary: SecondaryButton,
    columnMatchConfirm: ColumnMatchConfirmButton,
  },
};
