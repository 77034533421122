const getClassName = (tab, activePreview) => {
  const className =
    "text-purple-500 hover:text-purple-700 px-3 py-2 font-medium text-sm rounded-md cursor-pointer";
  return tab === activePreview ? `${className} bg-purple-100` : className;
};

export const PreviewTabs = ({ activePreview, setActivePreview }) => {
  return (
    <div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          <div
            onClick={() => setActivePreview("INITIAL")}
            className={getClassName("INITIAL", activePreview)}
          >
            Initial
          </div>
          <div
            onClick={() => setActivePreview("MATCH")}
            className={getClassName("MATCH", activePreview)}
          >
            Match
          </div>
          <div
            onClick={() => setActivePreview("REVIEW")}
            className={getClassName("REVIEW", activePreview)}
          >
            Review
          </div>
        </nav>
      </div>
    </div>
  );
};
