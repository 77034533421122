import { regexMatches } from "../utils/validatorLibrary";
/* EXAMPLE CONFIG FIELDS
fields: [
            {
              label: 'Robot Name',
              key: 'name',
              sizeHint: 2,
              description: '<img src="/Example.gif" alt="Example test image" width="240" />',
              shortDescription: 'Short description',
              validators: [
                {
                  validate: 'required_without_all',
                  fields: ['id', 'shield-color'],
                  error: 'must be present if no id and shield color'
                },
                {
                  validate: 'unique'
                }
              ]
            }
        }
*/

const buildValidatorObject = (validators) => {
  const verboseValidatorObject = validators.map((validator) => {
    return { validate: "regex_matches", ...regexMatches[validator] };
  });
  return verboseValidatorObject;
};

export const buildSchema = (cellData) => {
  const formattedSchema = cellData.map((row) => {
    const cells = row.cells;
    // check if label and key are set before adding to schema
    const hasLabelAndKey = row.cells[0].value && row.cells[1].value;
    let rowObj = {};

    if (hasLabelAndKey) {
      // create each field object
      cells.forEach((cell, idx) => {
        if (cell.value) {
          switch (idx) {
            case 0:
              rowObj["label"] = cell.value;
              return;
            case 1:
              rowObj["key"] = cell.value;
              return;
            case 2:
              rowObj["alternates"] = cell.value.map(
                (alternate) => alternate.label
              );
              return;
            case 3:
              // no need to add type: string to field, since string is default
              if (cell.value[0]?.label !== "string") {
                rowObj["type"] = cell.value[0]?.label;
              }
              return;
            case 4:
              rowObj["validators"] = buildValidatorObject(
                cell.value.map((validator) => validator.label)
              );
              return;
            case 5:
              if (cell.value.booleanValue) {
                rowObj["validators"].push({ validate: "required" });
              } else {
                rowObj["validators"] = rowObj["validators"].filter(
                  (validator) => validator.validate !== "required"
                );
              }
              return;
            case 6:
              if (cell.value.booleanValue) {
                rowObj["validators"].push({ validate: "unique" });
              } else {
                rowObj["validators"] = rowObj["validators"].filter(
                  (validator) => validator.validate !== "unique"
                );
              }
              return;
            default:
              return;
          }
        }
      });
    }

    return rowObj;
  });
  const filteredSchema = formattedSchema.filter((row) => {
    // check if label has been set
    return row && Object.keys(row).length;
  });
  // console.log(filteredSchema);
  return filteredSchema;
};
