import { useEffect, useState } from "react";
import { SchemaBuilderNav } from "../components/SchemaBuilderNav";
import { SchemaPreview } from "../components/SchemaPreview";
import { regexOptions } from "../utils/validatorLibrary";
import { buildSchema } from "../utils/buildSchema";
import { infoHelpers } from "../utils/infoHelpers";

import Table, {
  BooleanRenderer,
  BooleanRendererProps,
  DefaultEditorProps,
  DefaultRendererProps,
  LookupEditorProps,
  LookupOption,
  LookupEditor,
  LookupRenderer,
  MultiValueEditorProps,
  MultiValueRendererProps,
  MultiValueEditor,
  MultiValueRenderer,
} from "@turntable/core";
// import { SelectOptionsPanel } from "../components/SelectOptionsPanel";
import { reverseTitleCase } from "../utils/titleCase";

const multiValueRenderer = (props: MultiValueRendererProps) => (
  <MultiValueRenderer {...props} />
);
const multiValueEditor = (props: MultiValueEditorProps) => (
  <MultiValueEditor {...props} />
);

const lookupEditor = (
  props: DefaultEditorProps<LookupOption[]> & LookupEditorProps
) => {
  return <LookupEditor options={regexOptions} {...props} />;
};

const lookupRenderer = (
  props: DefaultRendererProps<{ value: LookupOption[] }>
) => {
  return <LookupRenderer options={regexOptions} {...props} />;
};

const fieldTypeOptions = [
  { label: "string" },
  { label: "select" },
  { label: "checkbox" },
];

const fieldTypeEditor = (
  props: DefaultEditorProps<LookupOption[]> & LookupEditorProps
) => {
  return (
    <LookupEditor
      options={fieldTypeOptions}
      singleSelectionOnly={true}
      {...props}
    />
  );
};

const fieldTypeRenderer = (
  props: DefaultRendererProps<{ value: LookupOption[] }>
) => {
  return (
    <LookupRenderer
      options={fieldTypeOptions}
      singleSelectionOnly={true}
      {...props}
    />
  );
};

const boolRenderer = (props: DefaultRendererProps<BooleanRendererProps>) => (
  <BooleanRenderer {...props} />
);

const getInfoIcon = (helperText?: string) => {
  return (
    <div className="has-tooltip relative">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5 info-icon"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
          clipRule="evenodd"
        />
      </svg>
      <div className="tooltip shadow-xl">{helperText}</div>
    </div>
  );
};

const boolColumns = ["Required", "Unique"];

const COLUMNS = [
  {
    // icon: getInfoIcon(infoHelpers["Label"]),
    value: "Label",
    required: true,
  },
  {
    // icon: getInfoIcon(infoHelpers["Key"]),
    value: "Key",
    required: true,
  },
  {
    // icon: getInfoIcon(infoHelpers["Alternates"]),
    value: "Alternates",
    renderer: multiValueRenderer,
    editor: multiValueEditor,
    disableClipboardEvents: true,
  },
  {
    // icon: getInfoIcon(infoHelpers["Field Type"]),
    value: "Field Type",
    renderer: fieldTypeRenderer,
    editor: fieldTypeEditor,
    disableClipboardEvents: true,
    singleSelectionOnly: true,
  },
  {
    value: "RegEx",
    renderer: lookupRenderer,
    editor: lookupEditor,
    disableClipboardEvents: true,
  },
  ...boolColumns.map((column) => {
    return {
      // icon: getInfoIcon(infoHelpers[column]),
      value: column,
      disableEditor: true,
      disableClipboardEvents: true,
      renderer: boolRenderer,
    };
  }),
];

const defaultArray = new Array(15).fill(
  Array.from({ length: COLUMNS.length }, () => {
    return { value: "" };
  })
);
const initialRows = defaultArray.map((row, index) => {
  const updateWithMultiSelect = row.map((cell, idx) => {
    // something is broken with field type here (column w/ index 3)
    if (idx === 3 || idx === 2 || idx === 4) {
      return { value: [] };
    } else return cell;
  });
  return { cells: updateWithMultiSelect, row: index };
});

export const TableBuilder = () => {
  const [showJSONPreview, setShowJSONPreview] = useState(false);
  const [formattedSchema, setFormattedSchema] = useState({});
  const [globalConfigs, setGlobalConfigs] = useState({});
  const [data, setData] = useState(initialRows);
  const [activeTab, setActiveTab] = useState<"Fields" | "Global Configs">(
    "Fields"
  );
  useEffect(() => {
    if (data) {
      const schema = buildSchema(data);
      setFormattedSchema({ fields: schema, ...globalConfigs });
    }
  }, [data, globalConfigs]);
  const handleCellsChange = async ({ changes }) => {
    // [
    //     {
    //         "row": 0,
    //         "patch": [
    //             {
    //                 "col": 0,
    //                 "value": "hhello"
    //             }
    //         ]
    //     }
    // ]

    const updatedData = changes.map((rowChange) => {
      const rowIndex = rowChange.row;
      const update = data.map((row) => {
        if (row.row === rowIndex) {
          // loop through patches + update state for each row patch
          const updateCell = rowChange.patch.map((patch, index) => {
            let autoSetVal = "";
            const newState = row.cells.map((currCell, idx) => {
              if (patch.col === 0) {
                // automatically set key value
                autoSetVal = reverseTitleCase(patch.value);
              }
              if (idx === 1 && currCell.value === "") {
                return { value: autoSetVal };
              }
              if (patch.col === idx) {
                return { value: patch.value };
              }
              return currCell;
            });
            return newState;
          });
          // find a way to not have to access idx 0
          return { cells: updateCell[0], row: row.row };
        }
        return row;
      });
      return update;
    });
    // find a way to not have to access idx 0
    await setData(updatedData[0]);
  };
  return (
    <div>
      <SchemaBuilderNav
        onDelete={() => {}}
        onTogglePanel={() => setShowJSONPreview(!showJSONPreview)}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <div style={{ height: "100vh", display: "flex" }}>
        {activeTab === "Fields" && (
          <>
            <Table
              count={15}
              columnConfig={COLUMNS}
              data={data}
              rowHeight={40}
              onCellsChange={async (e) => await handleCellsChange(e)}
            />
            {/* <SelectOptionsPanel
              cellData={[]}
              setCellData={() => {}}
              selectedRow={1}
            /> */}
          </>
        )}
        {activeTab === "Global Configs" && (
          <GlobalConfigs schema={globalConfigs} setSchema={setGlobalConfigs} />
        )}
        {showJSONPreview && (
          <SchemaPreview
            schema={formattedSchema}
            onClosePanel={() => setShowJSONPreview(false)}
          />
        )}
      </div>
    </div>
  );
};

const globalBools = {
  "Allow Custom": "allowCustom",
  "Allow Invalid Submit": "allowInvalidSubmit",
  "Dev Mode": "devMode",
  "Display Encoding": "displayEncoding",
  Managed: "managed",
};

const GlobalConfigColumns = [
  {
    // icon: getInfoIcon(infoHelpers["type"]),
    value: "type",
  },
  ...Object.keys(globalBools).map((column) => {
    return {
      // icon: getInfoIcon(infoHelpers[column]),
      value: column,
      disableEditor: true,
      disableClipboardEvents: true,
      renderer: boolRenderer,
    };
  }),
];

const defaultGlobalArray = new Array(1).fill(
  Array.from({ length: GlobalConfigColumns.length }, () => {
    return { value: "" };
  })
);

const formattedGlobalArray = defaultGlobalArray.map((row, index) => {
  return { cells: row, row: index };
});

const GlobalConfigs = ({ schema, setSchema }) => {
  const schemaCopy = schema;
  delete schemaCopy.fields;
  // loop through default global array
  // update the first row with a new array with schema values
  const updatedGlobalArray = formattedGlobalArray.map((row, idx) => {
    const updatedRow = row.cells.map((cell, idx) => {
      const matchedVal = Object.values(schemaCopy)[idx];
      if (idx !== 0) {
        return { value: { label: "", booleanValue: false } };
      }
      return { value: matchedVal };
    });
    return updatedRow;
  });
  const [globalData, setGlobalData] = useState([
    { cells: [...updatedGlobalArray[0]], row: 0 },
  ]);
  const handleCellChange = async (e) => {
    const colValue = e.changes[0].patch[0].col;
    const boolValue = e.changes[0].patch[0].value.booleanValue;
    const globalDataCopy = globalData[0].cells.map((cell, idx) => {
      if (colValue === 0 && colValue === idx) {
        return { value: e.changes[0].patch[0].value };
      }
      if (colValue === idx) {
        return { value: { booleanValue: boolValue } };
      }
      return cell;
    });
    console.log("hello testing", globalDataCopy);
    setGlobalData([{ cells: globalDataCopy, row: 0 }]);
  };
  useEffect(() => {
    console.log("globalData", globalData);
    const globalDataFormatted = {};
    globalData[0].cells.forEach((cell, idx) => {
      if (idx === 0) {
        globalDataFormatted["type"] = cell.value;
      } else if (idx !== 0) {
        const keyToUpdate = Object.values(globalBools)[idx - 1];
        const boolValue = cell.value.booleanValue;
        globalDataFormatted[keyToUpdate] = boolValue;
      }
    });
    setSchema(globalDataFormatted);
  }, [globalData]);
  return (
    <Table
      count={1}
      columnConfig={GlobalConfigColumns}
      data={globalData}
      rowHeight={40}
      onCellsChange={async (e) => await handleCellChange(e)}
    />
  );
};
