const ColorInput = ({ theme, name, attribute, buttonType, onChange }) => {
  return (
    <div className="mb-4">
      <label htmlFor="primary-button" className="text-xs">
        {name}
      </label>
      <div className="flex mt-1 relative shadow-md p-4 focus:ring-purple-500 focus:border-purple-500 block sm:text-sm border-gray-500 rounded-md">
        <input
          className="focus:outline-none"
          type="color"
          value={theme.buttons[buttonType][attribute]}
          onChange={(e) => onChange(e.target.value, attribute, buttonType)}
        />
        <div className="pl-4 font-bold">
          {theme.buttons[buttonType][attribute]}
        </div>
      </div>
    </div>
  );
};

const buttonOptions = {
  primary: ["backgroundColor", "color"],
  secondary: ["backgroundColor", "color"],
  columnMatchConfirm: ["backgroundColor", "color"],
};

export const ButtonStyler = ({ theme, setTheme }) => {
  const handleChange = (color, propType, buttonType) => {
    const updatedButtonType = {
      [buttonType]: {
        ...theme.buttons[buttonType],
        [propType]: color,
      },
    };
    const updatedTheme = {
      ...theme,
      buttons: {
        ...theme.buttons,
        ...updatedButtonType,
      },
    };
    setTheme(updatedTheme);
  };

  return (
    <>
      {Object.entries(buttonOptions).map(([key, options]) =>
        options.map((option) => (
          <ColorInput
            key={`${key}__${option}`}
            theme={theme}
            name={`${key} ${option}`}
            attribute={option}
            buttonType={key}
            onChange={handleChange}
          />
        ))
      )}
    </>
  );
};
