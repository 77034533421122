import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { DesignPlayground } from "./scenes/DesignPlayground";
import { TableBuilder } from "./scenes/TableBuilder";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/toppings" component={TableBuilder} />
        <Route path="/" component={DesignPlayground} />
      </Switch>
    </Router>
  );
};

export default App;
