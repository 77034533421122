export const titleCase = (source: string) => {
  return source
    .replace(/[a-z][A-Z]/g, (value) => `${value[0]} ${value[1]}`)
    .replace(/[\W_]+/g, " ")
    .replace(/\w\S*/g, (value) => {
      return value.charAt(0).toUpperCase() + value.substr(1);
    });
};

export const reverseTitleCase = (source: string) => {
  return source.split(" ").join("_");
};
