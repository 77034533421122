import logo from "../assets/logo.svg";

export const Nav = ({ children }: { children: any }) => {
  return (
    <nav className="bg-white border-b-2 border-grey-600 pb-4">
      <div className="px-2 sm:px-6 lg:px-12 mt-4">
        <div className="relative flex justify-between">
          <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex-shrink-0 flex items-center">
              <img
                className="hidden lg:block h-8 w-auto mr-40"
                src={logo}
                alt="flatfile"
              />
            </div>
            {children}
          </div>
        </div>
      </div>
    </nav>
  );
};
