import { Input } from "./Input";

const options = [
  "backgroundColor",
  "secondaryTextColor",
  "primaryTextColor",
  "textColor",
  "successColor",
  "warningColor",
];

export const ColorOptions = ({ theme, setTheme }) => {
  return (
    <div className="flex flex-col pb-4">
      {options.map((o, i) => (
        <div className={i === 0 ? "" : "mt-4"} key={o}>
          <Input name={o} theme={theme} setTheme={setTheme} />
        </div>
      ))}
    </div>
  );
};
