import { useState } from "react";
import "../App.css";
import { Sidebar } from "../components/Sidebar";
import { PreviewTabs } from "../components/PreviewTabs";
import { defaultTheme } from "../utils/defaultStyles";
import { Preview } from "../components/Preview";
import { JSONPreview } from "../components/JSONpreview";
import { Nav } from "../components/Nav";
import { ThemeDropdown } from "../components/ThemeDropdown";
import { RandomColorsButton } from "../components/RandomColorsButton";

export const DesignPlayground = () => {
  const [activeTab, setActiveTab] = useState("Colors");
  const [activePreview, setActivePreview] = useState("INITIAL");
  const [theme, setTheme] = useState(defaultTheme);

  return (
    <div>
      <Nav>
        <div className="flex justify-between items-center w-full mr-72 ml-10">
          <PreviewTabs
            activePreview={activePreview}
            setActivePreview={setActivePreview}
          />
          <div className="flex w-1/4 items-end">
            <ThemeDropdown theme={theme} setTheme={setTheme} />
            <RandomColorsButton theme={theme} setTheme={setTheme} />
          </div>
        </div>
      </Nav>
      <div className="flex wrapper">
        <Sidebar
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          theme={theme}
          setTheme={setTheme}
        />
        <>
          <Preview theme={theme} stage={activePreview} />
          <JSONPreview theme={theme} />
        </>
      </div>
    </div>
  );
};
