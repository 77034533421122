const getClassName = (tab, activeTab) => {
  const className =
    "text-purple-500 hover:text-purple-700 px-3 py-2 font-medium text-sm rounded-md cursor-pointer";
  return tab === activeTab ? `${className} bg-purple-100` : className;
};

export const Tabs = ({ activeTab, setActiveTab }) => {
  return (
    <div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4 justify-center" aria-label="Tabs">
          <div
            onClick={() => setActiveTab("Colors")}
            className={getClassName("Colors", activeTab)}
          >
            Colors
          </div>
          <div
            onClick={() => setActiveTab("Buttons")}
            className={getClassName("Buttons", activeTab)}
          >
            Buttons
          </div>
        </nav>
      </div>
    </div>
  );
};
