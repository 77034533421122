import { useEffect, useState } from "react";
import { ConfigTabs } from "../components/ConfigTabs";

import logo from "../assets/logo.svg";

export const SchemaBuilderNav = ({
  onDelete,
  onTogglePanel,
  activeTab,
  setActiveTab,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    if (isModalOpen) {
      const modal = document.querySelector(".modal");
      modal.classList.toggle("opacity-0");
      modal.classList.toggle("pointer-events-none");
      document.body.classList.toggle("modal-active");
    }
  }, [isModalOpen]);
  return (
    <nav className="bg-white">
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <div className="flex-shrink-0 flex items-center">
              <img
                className="hidden lg:block h-8 w-auto mr-40"
                src={logo}
                alt="flatfile"
              />
            </div>
          </div>
          <ConfigTabs activeTab={activeTab} setActiveTab={setActiveTab} />

          <div className="flex items-center">
            <div className="flex-shrink-0 mr-20 flex items-center justify-center">
              {/* <button
                type="button"
                className="mr-4 inline-flex items-center p-1.5 border border-transparent rounded-full shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                onClick={() => onDelete()}
              >
                <svg
                  className="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </button> */}
              {/* <button
                type="button"
                onClick={() => setIsModalOpen(true)}
                className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-purple-700 bg-purple-100 hover:bg-purple-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 mr-6"
              >
                <span>How to Ninja around here</span>
              </button> */}
              <button
                onClick={onTogglePanel}
                type="button"
                className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
              >
                <span>Preview JSON</span>
              </button>
            </div>
          </div>
          {/* {true && <InfoModal setIsModalOpen={setIsModalOpen} />} */}
        </div>
      </div>
    </nav>
  );
};
