import { labelMap } from "../utils/labelMap";

export const Input = ({ name, theme, setTheme }) => {
  const handleChange = (color) => {
    const newTheme = { ...theme, global: { ...theme.global, [name]: color } };
    setTheme(newTheme);
  };
  return (
    <>
      <label htmlFor={name} className="text-xs">
        {labelMap[name]}
      </label>
      <div className="flex mt-1 relative shadow-md p-4 focus:ring-purple-500 focus:border-purple-500 block sm:text-sm border-gray-500 rounded-md">
        <input
          className="focus:outline-none"
          type="color"
          value={theme.global[name]}
          onChange={(e) => handleChange(e.target.value)}
        />
        <div className="pl-4 font-bold">{theme.global[name]}</div>
      </div>
    </>
  );
};
